<template>
  <!--个人中心-->
  <div class="container user-main">
    <div class="wei">个人中心</div>
    <div class="user">
      <div class="user_left">
        <div class="info">
          <h3>登录教师端</h3>
          <h3 v-if="teacher">手机：{{ teacher.phone_number }}</h3>
          <el-button type="primary" class="btn_tui" @click="out">退出登录</el-button>
          <el-button type="primary" class="btn_tui" @click="$router.push({ path: '/revise' })">个人账户</el-button>
        </div>
        <!-- <h3 class="tit">个人荣誉</h3>
        <el-input type="textarea" autosize placeholder="个人信息内容" v-model="textarea1" class="txt_rongyu">
        </el-input> -->
        <!-- <el-button type="primary" class="btn_save" @click="changehonor">保存</el-button> -->
      </div>
      <!-- <div class="user_right">
        <el-select v-model="value" placeholder="请选择" @change="changeClass">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="list">
          <div v-for="(item, index) in list" :key="index">
            <div class="nei">
              <h3 class="tit">{{ item.name }}</h3>
              <div style="display: flex; justify-content: space-between;">
                <div>
                  <p class="p1">12</p>
                  <p>人数</p>
                </div>
                <div>
                  <p class="p2">22</p>
                  <div><img style="width: 30px; height: 30px;" src="../../assets/img/14.png" /></div>
                </div>
                <div>
                  <p class="p3">33</p>
                  <div><img style="width: 30px; height: 30px;" src="../../assets/img/15.png" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

  </div>
  <!-- <div class="user">
    <el-menu :default-active="activeIndex" class="el-menu-demo" router mode="horizontal" @select="handleSelect">
      <el-menu-item index="/student">学生管理</el-menu-item>
      <el-menu-item index="/parent">家长管理</el-menu-item>
      <el-menu-item index="/teachet">教师管理</el-menu-item>
    </el-menu>
    <el-select v-model="value" placeholder="请选择">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <div class="search_user">
      <div class="search_left">
        <el-input v-model="searchValue" placeholder="请输搜索入内容"></el-input>
        <img src="~img/03.png" alt="">
      </div>
      <el-button type="primary" round>添加</el-button>
    </div>
    <main>
      <router-view></router-view>
    </main>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      value: "",
      searchValue: "",
      textarea1: "",
      options: [],
      list: [
        { name: "2022-1班", p1: "50", p2: "30", p3: "20" },
      ],
      level_list: [],
      teacher: null
    };
  },
  created() {
    this.get_info();
    // this.get_class();
  },
  methods: {
    handleSelect(key, keyPath) { },
    changeClass(val) {
      this.list = this.level_list.filter(item => item.id === val)[0].classes;
    },
    async get_info() {
      const res = await this.axios.get("/auth/info");

      if (res.code === 1) {
        this.teacher = res.data;
        this.textarea1 = res.data.honor;
      }
    },
    async get_class() {
      const res = await this.axios.get("/level/classes");
      if (res.code === 1) {
        this.level_list = res.data;
        res.data.forEach(item => {
          this.options.push({ value: item.id, label: item.name });
        });
        this.list = res.data[0].classes;
      }
    },
    async changehonor() {
      const res = await this.axios.post(`/auth/honor`, {
        row: {
          honor: this.textarea1
        }
      });

      if (res.code === 1) {
        this.$message.success("更新成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    out() {
      localStorage.setItem("token", "");
      this.$router.push({ path: "/login" });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 20px;
  .user {
    display: flex;
    .user_right{
      .list{
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        .nei{
          padding: 15px 20px;
          background: #f2f2f2;
          border-radius: 33px 106px 33px 33px;
          height: 170px;
        }
      }
    }
  }
  .wei {
    font-size: 28px;
    color: #112bb4;
    font-weight: bold;
  }

  .user_left {
    width: 50%;
    .btn_save{
      margin-top: 20px;
    }
  }
}

.user {
  // width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  padding: 20px;

  .el-select {
    position: absolute;
    right: 20px;
    top: 20px;

    /deep/.el-input__inner {
      border-radius: 10px;
    }
  }

  .search_user {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    .search_left {
      position: relative;
      width: 272px;

      img {
        width: 40px;
        position: absolute;
        right: 0;
        top: 0;
      }

      .el-input {
        /deep/.el-input__inner {
          border-radius: 10px;
          background-color: #f2f3f5;
        }
      }
    }

    .el-button {
      background-color: #537af2;
    }
  }

  main {
    padding-top: 20px;
  }
}</style>
